import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Product, ProductVariant } from "../../../model/features/productsSlice";

import wineBottle from "./wine-bottle.svg";
import styles from "./CollectionItem.module.scss";
import { Suspense } from "react";
import Spinner from "../../Spinner/Spinner";
import { getBottleSizeKey } from "../../../utils";

interface CollectionItemProps {
  product: Product,
  variant: ProductVariant
}

const CollectionItem = (props: CollectionItemProps) => {
  const { product, variant } = props;
  const { t } = useTranslation();

  let imageSrc = wineBottle;
  if (variant.pictures.length > 0) {
    imageSrc = variant.pictures[0];
  }

  let size = t(getBottleSizeKey(variant.size));

  return <Link to={`${product.id}/${variant.year}-${variant.size}`} className={styles.Item}>
    <div className={styles.Image}>
      <img src={imageSrc} alt={product.name} />
    </div>
    <div className={styles.Text}>
      <div className={styles.Title}>{product.name}</div>
      <div>{variant.year} - {size}</div>
    </div>
  </Link>;
};

const SuspenseCollectionItem = (props: CollectionItemProps) => <Suspense fallback={<Spinner />}>
  <CollectionItem {...props} />
</Suspense>;

export default SuspenseCollectionItem;