import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { AppThunk } from "../reducer";

export interface ProductVariant {
  year: number;
  size: number;
  minPrice: number;
  pictures: string[];
  inventory: number;
}

export interface Product {
  id: string;
  name: string;
  description?: string;
  denomination: string;
  brand: string;
  type: "red" | "white" | "rose" | "sparkling" | "sweet";
  location: string;
  variants: ProductVariant[];
}

interface ProductsSlice {
  products: Product[];
  brands: string[];
  selectedBrand?: string;
  filteredProducts: Product[];
  loading: boolean;
  error?: string;
}

interface ApiResponse {
  success: boolean;
}

type WineResponse = ApiResponse & {
  wine: Product;
};

type WinesResponse = ApiResponse & {
  wines: Product[];
};

type BrandsResponse = ApiResponse & {
  brands: string[];
};

export const loadProducts = (): AppThunk => {
  return async (dispatch) => {
    const {
      startLoading,
      doneLoading,
      loadingError,
      setBrandFilter,
      setBrands,
      setProducts,
    } = productsSlice.actions;

    dispatch(startLoading());
    dispatch(setBrandFilter());

    try {
      const { data: productsData } = await api.get<WinesResponse>("/wines");
      dispatch(setProducts(productsData.wines));

      const { data: brandsData } = await api.get<BrandsResponse>("/brands");
      const sortedBrands = brandsData.brands.sort((a, b) =>
        a < b ? -1 : a > b ? 1 : 0
      );
      dispatch(setBrands(sortedBrands));

      dispatch(doneLoading());
    } catch (err) {
      dispatch(loadingError(err.message || err));
    }
  };
};

export const loadProductById = (id: string): AppThunk => {
  return async (dispatch) => {
    const {
      startLoading,
      doneLoading,
      loadingError,
      addProduct,
    } = productsSlice.actions;

    dispatch(startLoading());

    try {
      const { data: productData } = await api.get<WineResponse>(`/wines/${id}`);
      dispatch(addProduct(productData.wine));

      dispatch(doneLoading());
    } catch (err) {
      dispatch(loadingError(err.message || err));
    }
  };
};

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    filteredProducts: [],
    brands: [],
    loading: false,
  } as ProductsSlice,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.error = undefined;
    },
    doneLoading(state) {
      state.loading = false;
    },
    loadingError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setProducts(state, action: PayloadAction<Product[]>) {
      state.products = action.payload;
      state.filteredProducts = action.payload;
      state.error = undefined;
    },
    addProduct(state, action: PayloadAction<Product>) {
      state.products.push(action.payload);
      state.error = undefined;
    },
    setBrands(state, action: PayloadAction<string[]>) {
      state.brands = action.payload;
      state.error = undefined;
    },
    setBrandFilter(state, action: PayloadAction<string | undefined>) {
      state.selectedBrand = action.payload;
      if (action.payload) {
        state.filteredProducts = state.products.filter(
          (product) => product.brand === action.payload
        );
      } else {
        state.filteredProducts = state.products;
      }
    },
  },
});

export const { setBrandFilter } = productsSlice.actions;
export default productsSlice.reducer;
