import { Component, Suspense } from "react";
import { withTranslation, WithTranslation } from "react-i18next"; 
import { Helmet } from "react-helmet";
import { connect, ConnectedProps } from "react-redux";
import { Link, Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { startOffer } from "../../model/features/offerSlice";
import { ProductVariant, loadProductById } from "../../model/features/productsSlice";
import { RootState } from "../../model/reducer";
import { getBottleNameKey, getBottleSizeKey, HOSTNAME } from "../../utils";
import Spinner from "../Spinner/Spinner";
import Offer from "../Offer/Offer";

import styles from "./Wine.module.scss";
import schedule from "./schedule.svg";
import grape from "./grape.svg";
import explore from "./explore.svg";
import bottle from "./wine-bottle.svg";
import bottleGrey from "./wine-bottle-grey.svg";

const mapStateToProps = (state: RootState) => ({
  offer: state.offer,
  products: state.products
});

const mapDispatchToProps = {
  loadProductById,
  startOffer
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type WineProps = ConnectedProps<typeof connector> & RouteComponentProps<{
  wineId: string;
  yearSize?: string;
  language: string;
}> & WithTranslation;

class Wine extends Component<WineProps> {

  componentDidMount() {
    const { wineId } = this.props.match.params;
    const { products, loadProductById } = this.props;

    let product = products.products.find(p => p.id === wineId);
    if (!product) {
      loadProductById(wineId);
    }

    window.scrollTo(0, 0);
  }

  render() {
    const { wineId, yearSize, language } = this.props.match.params;
    const { products, startOffer } = this.props;
    const { pathname } = this.props.location;
    const { t } = this.props;

    let product = products.products.find(p => p.id === wineId);

    let variant: ProductVariant | undefined;
    if (yearSize) {
      let [year, size] = yearSize.split('-');
      variant = product?.variants.find(v => v.year === +year && v.size === +size);
    }

    if (product && product.variants.length > 0 && !yearSize && !variant) {
      variant = [...product.variants].sort((a, b) => a.year - b.year)[0];
    }

    let redirect;
    if (product && !variant) {
      redirect = <Redirect to={`/${language}/wines/`} />;
    }

    let images: JSX.Element | JSX.Element[] = <div className={styles.Image}>
      <img src={bottleGrey} alt={t("wine.placeholderImageAlt")} />
    </div>;
    if (product && variant && variant.pictures.length > 0) {
      images = variant.pictures.map(image => <div key={image} className={styles.Image}>
        <img src={image} alt={product!.name} />
      </div>);
    }

    let variants;
    if (product) {
      variants = product.variants.map(v => {

        let isThisVariant = v.year === variant?.year && v.size === variant.size;
        let buttonText = `${v.year}`;
        if (v.size !== 0.75 || product?.variants.find(variant => variant.year === v.year && variant.size !== v.size)) {
          buttonText = `${v.year} - ${t(getBottleSizeKey(v.size))}`;
        }

        return <Link to={`/${language}/wines/${product!.id}/${v.year}-${v.size}`} key={`${v.year}_${v.size}`}>
          <button className={styles.VariantButton} disabled={isThisVariant}>
            {buttonText}
          </button>
        </Link>;
      });
    }

    // let offerModal;
    // if (offer.open) {
    //   offerModal = <Offer />;
    // }

    let content;
    if (products.loading) {
      content = <Spinner />;
    } else {
      content = <div className={styles.Table}>
        <div className={styles.Images}>
          {images}
        </div>
        <div className={styles.Details}>
          <h1>{product?.name}</h1>
          <h3>{product?.brand}</h3>

          <h3 className={styles.Variant}>
            <div>
              <img src={schedule} alt={t("wine.yearImageAlt")} />
              <span>{variant?.year}</span>
            </div>
            <div>
              <img src={bottle} alt={t("wine.sizeImageAlt")} />
              <span>{variant && variant.size ? t(getBottleNameKey(variant.size)) : null} - {variant?.size ? t(getBottleSizeKey(variant.size)) : null}</span>
            </div>
            <div>
              <img src={grape} alt={t("wine.denominationImageAlt")} />
              <span>{product?.denomination} ({product ? t(`types.${product.type}`) : null})</span>
            </div>
            {product && product.location ? <div>
              <img src={explore} alt={t("wine.originImageAlt")} />
              <span>{t("wine.originTitle")}: {t(`countries.${product.location}`)}</span>
            </div> : null}

          </h3>

          <p>{product?.description}</p>

          <div className={styles.Years}>
            <h3>{t("wine.variantsTitle")}</h3>
            <div>
              {variants}
            </div>
          </div>
            <h3 className={styles.Inventory}>{variant?.inventory && variant.inventory < 6 ? `${t("wine.inventory.only")} ` : null}{variant?.inventory && variant.inventory > 9 ? "9+" : variant?.inventory} {t("wine.inventory.text", { count: variant?.inventory })}</h3>
          <div>
            <p>{t("wine.inventory.note")}</p>
            <button onClick={() => product && variant ? startOffer({
              product,
              variant
            }) : null}>{t("wine.cta")}</button>
          </div>
        </div>
      </div>;
    }

    let seoName = `${product?.name} - ${variant?.year} - ${variant ? t(getBottleSizeKey(variant.size)) : ""}`;
    let seoDescription = product?.description || `${product?.name} - ${product?.denomination} - ${variant?.year} - ${variant ? t(getBottleSizeKey(variant.size)) : ""}`;
    let seoUrl = `${HOSTNAME}${pathname}`;

    return <div className={styles.Container}>
      <Helmet>
        <title>{product ? `${seoName} | ${t("seo.title")}` : t("seo.title")}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:url" content={seoUrl} />
        <meta property="og:title" content={seoName} />
        <meta property="og:type" content="product" />
        <meta property="product:price:amount" content={variant?.minPrice.toFixed(2)} />
        <meta property="product:price:currency" content="EUR" />
        <meta property="og:description" content={seoDescription} />
        {variant && variant.pictures.length > 0 ? <meta property="og:image" content={`${HOSTNAME}${variant.pictures[0]}`} /> : null}
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "name": "${seoName}",
          "description": "${seoDescription}",
          ${variant && variant.pictures.length > 0 ? `"image": "${HOSTNAME}${variant.pictures[0]}",` : null}
          "offers": {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "price": "${variant?.minPrice.toFixed(2)}",
            "priceCurrency": "EUR",
            "inventoryLevel": {
              "value": ${variant?.inventory}
            },
            "url": "${seoUrl}"
          },
          "brand": {
            "name": "${product?.brand}"
          },
          "manufacturer": {
            "name": "${product?.brand}"
          }
        }
        `}</script>
      </Helmet>
      <div className={styles.Header}>
        <Link to={`/${language}/wines/`}>&lt; {t("wine.back")}</Link>
      </div>
      {content}
      {redirect}
      <Offer />
    </div>;
  }

};

const ConnectedWine = withTranslation()(withRouter(connector(Wine)));

const SuspenseWine = () => <Suspense fallback={<Spinner />}>
  <ConnectedWine />
</Suspense>;

export default SuspenseWine;