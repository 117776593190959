import { Action, combineReducers, ThunkAction } from "@reduxjs/toolkit";
import offerSlice from "../features/offerSlice";
import productsSlice from "../features/productsSlice";

const rootReducer = combineReducers({
  products: productsSlice,
  offer: offerSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default rootReducer;
