import React from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import CookieMessage from "./components/CookieMessage/CookieMessage";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from "./components/Home/Home";
import Wine from './components/Wine/Wine';
import Collection from './components/Collection/Collection';
import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import LanguageManager from './components/LanguageManager/LanguageManager';

import styles from "./App.module.scss";

function App() {
  return (
    <Router>
      <div className={styles.App}>
        <Header />
        <div className={styles.Body}>
          <Switch>
            <Route path="/:language(en|it)/wines/:wineId/:yearSize?" exact>
              <Wine />
            </Route>
            <Route path="/:language(en|it)/wines/" exact>
              <Collection />
            </Route>
            <Route path="/:language(en|it)/" exact>
              <Home />
            </Route>
            <Route path="/" exact>
              <LanguageSelector />
            </Route>
            <Route path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
        <Footer />
        <LanguageManager />
        <CookieMessage />
      </div>
    </Router>
  );
}

export default App;
