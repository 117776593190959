import { Suspense, useRef } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { connect, ConnectedProps } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { RootState } from "../../model/reducer";
import {
  clearError,
  closeOffer,
  updateField,
  updateQuantity,
  sendError,
  sendOffer,
  OfferFormFields,
  OfferData
} from "../../model/features/offerSlice";
import Spinner from "../Spinner/Spinner";
import CountrySelect from "./CountrySelect";
import { getBottleSizeKey } from "../../utils";

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d3b367"
    }
  },
  typography: {
    fontFamily: [
      "'Montserrat'",
      "sans-serif"
    ].join(",")
  }
});

const mapStateToProps = (state: RootState) => ({
  offer: state.offer
});

const mapDispatchToProps = {
  clearError,
  closeOffer,
  sendError,
  sendOffer,
  updateField,
  updateQuantity,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OfferProps = ConnectedProps<typeof connector> & WithTranslation;

const Offer = ({
  offer,
  clearError,
  closeOffer,
  sendError,
  sendOffer,
  updateField,
  updateQuantity,
  t,
}: OfferProps) => {

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleClose = () => {
    closeOffer();
  };

  const updateFormField = (name: keyof OfferFormFields, value: string) => {
    updateField({ name, value });
  };

  const updateBottles = (value: number) => {
    updateQuantity(value);
  };

  const handleSend = async () => {
    const token = await recaptchaRef.current?.executeAsync();
    if (token) {
      const offerData: OfferData = {
        name: offer.name,
        companyName: offer.companyName,
        country: offer.country,
        email: offer.email,
        phone: offer.phone,
        comments: offer.comments,
        quantity: offer.quantity,
        product: offer.product,
        variant: offer.variant,
        offer: offer.offer
      };
      sendOffer(offerData, token);
    } else {
      // Error with Recaptcha
      sendError(t("offer.errors.recaptcha"));
    }
  }

  const isComplete = offer.name.trim().length > 0 &&
    offer.quantity > 0 &&
    offer.country.trim().length > 0 &&
    offer.email.trim().length > 0 &&
    offer.phone.trim().length > 0 &&
    offer.offer.trim().length > 0;

  let quantitySelector = <>
    <Typography gutterBottom color="primary" style={{ marginTop: ".5rem" }}>
      Quantity *
    </Typography>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs style={{ marginLeft: ".5rem" }}>
        <Slider
          value={offer.quantity}
          onChange={(_event, newValue) => updateBottles(newValue as number)}
          step={1}
          min={1}
          max={offer.variant?.inventory || 1} />
      </Grid>
      <Grid item>
        <Input
          inputProps={{
            min: 1,
            max: offer.variant?.inventory || 1,
            step: 1,
            type: "number"
          }}
          value={offer.quantity}
          onChange={(event) => updateBottles(+event.target.value)}
          required />
      </Grid>
    </Grid>
  </>;
  if (offer.variant?.inventory === 1) {
    quantitySelector = <TextField
      margin="dense"
      label={t("offer.quantity")}
      fullWidth
      disabled
      value={1} />;
  }

  let dialogContent;
  if (offer.loading) {
    dialogContent = <Spinner />;
  } else if (offer.success) {
    dialogContent = <DialogContentText>{t("offer.thankYou")}</DialogContentText>;
  } else {
    dialogContent = <>
      <DialogContentText>{t("offer.description")}</DialogContentText>
      <form>
        <TextField
          margin="dense"
          label={t("offer.wine")}
          fullWidth
          disabled
          value={`${offer.product?.name} - ${offer.variant?.year} - ${offer.variant ? t(getBottleSizeKey(offer.variant.size)) : null}`} />
        {quantitySelector}
        <TextField
          margin="dense"
          label={t("offer.offer.label")}
          value={offer.offer}
          placeholder={t("offer.offer.placeholder")}
          onChange={(e) => updateFormField("offer", e.currentTarget.value)}
          type="text"
          required
          fullWidth />
        <TextField
          margin="dense"
          label={t("offer.name")}
          value={offer.name}
          onChange={(e) => updateFormField("name", e.currentTarget.value)}
          required
          fullWidth />
        <TextField
          margin="dense"
          label={`${t("offer.company")} (${t("offer.optional")})`}
          value={offer.companyName}
          onChange={(e) => updateFormField("companyName", e.currentTarget.value)}
          fullWidth />
        <FormControl fullWidth style={{ marginTop: '.25rem' }}>
          <InputLabel margin="dense">{t("offer.country")} *</InputLabel>
          <CountrySelect
            margin="dense"
            value={offer.country}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => updateFormField("country", e.currentTarget.value as string)}
            required
            fullWidth />
        </FormControl>
        <TextField
          margin="dense"
          label={t("offer.email")}
          value={offer.email}
          onChange={(e) => updateFormField("email", e.currentTarget.value)}
          type="email"
          required
          fullWidth />
        <TextField
          margin="dense"
          label={t("offer.phone")}
          value={offer.phone}
          onChange={(e) => updateFormField("phone", e.currentTarget.value)}
          type="tel"
          required
          fullWidth />
        <TextField
          margin="dense"
          label={t("offer.comments")}
          value={offer.comments}
          onChange={(e) => updateFormField("comments", e.currentTarget.value)}
          multiline
          rowsMax={6}
          fullWidth />
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LdiCSwaAAAAAA0UXu_MA3ymzVHP3dCQiuwoTKkD" />
      </form>
      <DialogContentText style={{ marginTop: '1rem', marginBottom: '0' }}>
        {t("offer.footnote")}
      </DialogContentText>
    </>;
  }

  let dialogButtons = <>
    <Button onClick={handleClose} color="primary">{t("offer.cancelButton")}</Button>
    <Button onClick={handleSend} color="primary" disabled={!isComplete || offer.loading}>{t("offer.submitButton")}</Button>
  </>;
  if (offer.success) {
    dialogButtons = <Button onClick={handleClose} color="primary">{t("offer.doneButton")}</Button>;
  }

  return <div>
    <ThemeProvider theme={theme}>
      <Dialog open={offer.open} onClose={handleClose}>
        <DialogTitle>{offer.success ? t("offer.title.done") : t("offer.title.form")}</DialogTitle>
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          {dialogButtons}
        </DialogActions>
      </Dialog>
      <Snackbar open={!!offer.error} autoHideDuration={6000} onClose={() => clearError()}>
        <Alert onClose={() => clearError()} severity="error">
          {offer.error}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  </div>;
};

const ConnectedOffer = withTranslation()(connector(Offer));

const SuspenseOffer = () => <Suspense fallback={<Spinner />}>
  <ConnectedOffer />
</Suspense>;

export default SuspenseOffer;