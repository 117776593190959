import { Suspense } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../../logo.svg";
import Spinner from "../Spinner/Spinner";
import styles from "./Header.module.scss";

const Header = () => {
  const { t } = useTranslation();

  return <div className={styles.Header}>
    <div></div>
    <div>
      <Link to="/">
        <img src={logo} className={styles.Image} alt={t("seo.title")} />
      </Link>
    </div>
    <div className={styles.Link}>
      <a href="https://www.ilconte.style/" target="_blank" rel="noreferrer">{t("header.fashionLink")}</a>
    </div>
  </div>;
};

const SuspenseHeader = () => <Suspense fallback={<Spinner />}>
  <Header />
</Suspense>;

export default SuspenseHeader;
