import NativeSelect, { NativeSelectProps } from "@material-ui/core/NativeSelect";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner";
import { COUNTRIES } from "../../utils";

const CountrySelect = (props: NativeSelectProps) => {

  const { t } = useTranslation();

  return <NativeSelect {...props}>
    <option value=""></option>
    {COUNTRIES.map(code => <option
      key={code}
      value={code}>
      {t(`countries.${code}`)}
    </option>)}
  </NativeSelect>;
};

const SuspenseCountrySelect = (props: NativeSelectProps) => <Suspense fallback={<Spinner />}>
  <CountrySelect {...props} />
</Suspense>;

export default SuspenseCountrySelect;