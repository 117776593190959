import { Component, Suspense } from "react";
import { Helmet } from "react-helmet";
import { withTranslation, WithTranslation } from "react-i18next";
import { RootState } from "../../model/reducer";
import { loadProducts, setBrandFilter } from "../../model/features/productsSlice";

import styles from "./Collection.module.scss";
import { connect, ConnectedProps } from "react-redux";
import Spinner from "../Spinner/Spinner";
import CollectionItem from "./CollectionItem/CollectionItem";
import { HOSTNAME } from "../../utils";

const mapStateToProps = (state: RootState) => ({
  products: state.products
});

const mapDispatchToProps = {
  loadProducts,
  setBrandFilter
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CollectionProps = ConnectedProps<typeof connector> & WithTranslation

class Collection extends Component<CollectionProps> {

  componentDidMount() {
    const { loadProducts } = this.props;
    loadProducts();
  }

  render() {

    const { products, setBrandFilter, t } = this.props;

    let brandFilters = [
      t("collection.filter.all"),
      ...products.brands
    ].map(brand => {
      let isSelected = (!products.selectedBrand && brand === t("collection.filter.all")) || products.selectedBrand === brand;
      let classes = [
        styles.BrandFilter,
        isSelected ? styles.Selected : null
      ];

      let targetBrand: string | undefined = brand;
      if (targetBrand === t("collection.filter.all")) {
        targetBrand = undefined;
      }

      return <button
        key={brand}
        className={classes.join(" ")}
        disabled={isSelected}
        onClick={() => setBrandFilter(targetBrand)}
      >{brand}</button>;
    });

    let items = [...products.filteredProducts]
      .sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))
      .map(product => {
        return product.variants
          .map(variant => ({
            ...variant,
            productInfo: product
          }))
          .sort((a, b) => a.year - b.year);
      })
      .reduce((arr, productVariants) => [...arr, ...productVariants], [])
      .map(item => {
        let { productInfo: product, ...variant } = item;
        return <CollectionItem
          key={`${product.id}_${variant.year}_${variant.size}`}
          product={product}
          variant={variant} />;
      });

    let content;
    if (products.loading) {
      content = <Spinner />;
    } else {
      content = <div className={styles.Table}>
        <div className={styles.Filters}>
          <h3>{t("collection.filter.title")}</h3>
          {brandFilters}
        </div>
        <div className={styles.Grid}>
          {items}
        </div>
      </div>;
    }

    return <div className={styles.Collection}>
      <Helmet>
        <title>{t("collection.pageTitle")} | {t("seo.title")}</title>
        <meta name="description" content={t("collection.pageDescription")} />
        <meta property="og:url" content={`${HOSTNAME}/wines/`} />
        <meta property="og:title" content={t("collection.pageTitle")} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={t("collection.pageDescription")} />
      </Helmet>
      <div className={styles.Header}>
        <h1>{t("collection.title")}</h1>
        <p>{t("collection.subtitle")}</p>
      </div>
      {content}
    </div>;
  }
};

const ConnectedCollection = withTranslation()(connector(Collection));

const SuspenseCollection = () => <Suspense fallback={<Spinner />}>
  <ConnectedCollection />
</Suspense>;

export default SuspenseCollection;