import { Suspense } from "react";
import CookieBanner from "react-cookie-banner";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner";

import styles from "./CookieMessage.module.scss";

const CookieMessage = () => {
  const { t } = useTranslation();

  return <div className={styles.Cookie}>
    <CookieBanner message={t("cookies")} onAccept={() => { }} cookie="cookie-banner-accept" />
  </div>;
};

const SuspenseCookieMessage = () => <Suspense fallback={<Spinner />}>
  <CookieMessage />
</Suspense>;

export default SuspenseCookieMessage;