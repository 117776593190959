import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Suspense } from "react";

import Spinner from "../Spinner/Spinner";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../../utils";

const LanguageSelector = () => {

  const { i18n } = useTranslation();
  let language;
  for (let lang of i18n.languages) {
    if (SUPPORTED_LANGUAGES.indexOf(lang) > -1) {
      language = lang;
      break;
    }
  }

  if (!language) {
    language = DEFAULT_LANGUAGE;
  }

  return <Redirect to={`/${language}/`} />;
};

const SuspenseLanguageSelector = () => <Suspense fallback={<Spinner />}>
  <LanguageSelector />
</Suspense>

export default SuspenseLanguageSelector;