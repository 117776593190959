import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { AppThunk } from "../reducer";
import { Product, ProductVariant } from "./productsSlice";

export interface OfferFormFields {
  name: string;
  companyName: string;
  country: string;
  email: string;
  phone: string;
  comments: string;
  offer: string;
}

export type OfferData = OfferFormFields & {
  quantity: number;
  product?: Product;
  variant?: ProductVariant;
};

type OfferSlice = OfferData & {
  open: boolean;
  loading: boolean;
  success: boolean;
  error?: string;
};

const initialState: OfferSlice = {
  open: false,
  loading: false,
  success: false,
  error: undefined,
  name: "",
  companyName: "",
  country: "",
  email: "",
  phone: "",
  comments: "",
  offer: "",
  quantity: 1,
  product: undefined,
  variant: undefined,
};

export const sendOffer = (
  offer: OfferData,
  recaptchaToken: string
): AppThunk => {
  return async (dispatch) => {
    const { startLoading, sendError, sendSuccess } = offerSlice.actions;

    dispatch(startLoading());

    try {
      const data = {
        ...offer,
        recaptcha: recaptchaToken,
      };
      await api.post("/offer", data);
      dispatch(sendSuccess());
    } catch (err) {
      dispatch(sendError(err.message || err.toString()));
    }
  };
};

const offerSlice = createSlice({
  name: "offer",
  initialState: initialState,
  reducers: {
    startOffer(
      _state,
      action: PayloadAction<{
        product: Product;
        variant: ProductVariant;
      }>
    ) {
      return {
        ...initialState,
        open: true,
        product: action.payload.product,
        variant: action.payload.variant,
      };
    },
    closeOffer(state) {
      state.open = false;
      state.product = undefined;
      state.variant = undefined;
    },
    updateField(
      state,
      action: PayloadAction<{
        name: keyof OfferFormFields;
        value: string;
      }>
    ) {
      state[action.payload.name] = action.payload.value;
    },
    updateQuantity(state, action: PayloadAction<number>) {
      state.quantity = action.payload;
    },
    startLoading(state) {
      state.loading = true;
      state.success = false;
    },
    sendSuccess(state) {
      state.loading = false;
      state.success = true;
      state.error = undefined;
    },
    sendError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    clearError(state) {
      state.error = undefined;
    },
  },
});

export const {
  startOffer,
  clearError,
  closeOffer,
  sendError,
  updateField,
  updateQuantity,
} = offerSlice.actions;
export default offerSlice.reducer;
