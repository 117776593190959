import { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../Spinner/Spinner";
import { HOSTNAME } from "../../utils";

const LanguageManager = () => {

  let { i18n } = useTranslation();
  let location = useLocation();

  let path = location.pathname.split('/').slice(2).join('/');

  return <Helmet>
    <html lang={i18n.language} />
    {i18n.languages.map(lang => <link key={lang} rel="alternate" hrefLang={lang} href={`${HOSTNAME}/${lang}/${path}`} />)}
  </Helmet>;
};

const SuspenseLanguageManager = () => <Suspense fallback={<Spinner />}>
  <LanguageManager />
</Suspense>;

export default SuspenseLanguageManager;