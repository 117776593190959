import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import styles from "./Footer.module.scss";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    let newLocation = `/${lang}/${location.pathname.split("/").slice(2).join("/")}`;
    history.push(newLocation);
  };

  const year = (new Date()).getFullYear();

  return <div className={styles.Footer}>
    <div>
      {t("footer.copyright", { year })}
    </div>
    <div style={{textAlign: "right"}}>
      <a href="/api/privacy" target="_blank">Privacy Policy</a> | <button onClick={() => changeLanguage('en')}>English</button> | <button onClick={() => changeLanguage('it')}>Italiano</button>
    </div>
  </div>;
};

const SuspenseFooter = () => <Suspense fallback={<Spinner />}>
  <Footer />
</Suspense>

export default SuspenseFooter;
