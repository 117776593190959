import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";
import { HOSTNAME } from '../../utils';
import Spinner from '../Spinner/Spinner';

import styles from './Home.module.scss';
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import hero from "./hero.jpg";

const Home = () => {
  const { t, i18n } = useTranslation();

  return <div className={styles.Home}>
    <Helmet>
      <title>Home | {t("seo.title")}</title>
      <meta name="description" content={t("seo.description")} />
      <meta property="og:url" content={`${HOSTNAME}/${i18n.language}/`} />
      <meta property="og:title" content="Home" />
      <meta property="og:description" content={t("seo.description")} />
      <meta property="og:image" content={`${HOSTNAME}${hero}`} />
      <meta property="og:type" content="website" />
    </Helmet>
    <div className={styles.Hero} style={{ backgroundImage: `url("${hero}")` }}>
      <h1>{t("home.hero")}</h1>
      <Link to="wines/">
        <button>{t("home.cta")} &gt;</button>
      </Link>
    </div>
    <div className={styles.Section}>
      <div className={styles.Text}>
        <h2>{t("home.section1.title")}</h2>
        <p>
          <Trans i18nKey="home.section1.content">
            All our bottles have been stored perfectly still for their entire lifetime in Italy, two locations at most, at constant atmospheric conditions (<b>14-16 &deg;C temperature and 65-70% humidity</b>). The collection includes a selection of reds, sweet wines, ros&eacute; and champagne.
          </Trans>
        </p>
      </div>
      <div className={styles.Image}>
        <img src={image1} alt="Wine" />
      </div>
    </div>
    <div className={styles.Section}>
      <div className={styles.Image}>
        <img src={image2} alt="Wine" />
      </div>
      <div className={styles.Text}>
        <h2>{t("home.section2.title")}</h2>
        <p>{t("home.section2.content")}</p>
      </div>
    </div>
    <div className={styles.Section}>
      <div className={styles.Text}>
        <h2>{t("home.section3.title")}</h2>
        <p>{t("home.section3.content")}</p>
        <Link to="wines/">
          <button>{t("home.cta")} &gt;</button>
        </Link>
      </div>
    </div>
    <div className={styles.Section} style={{ flexDirection: "column" }}>
      <div className={styles.Image}>
        <img src={image3} alt="Wine" />
      </div>
      <div className={styles.Image}>
        <img src={image4} alt="Wine" />
      </div>
      <div className={styles.Image}>
        <img src={image5} alt="Wine" />
      </div>
    </div>
  </div>;
};

const SuspenseHome = () => <Suspense fallback={<Spinner />}>
  <Home />
</Suspense>;

export default SuspenseHome;